import React from "react";

const Footer: React.FC = () => {
  return (
    <div className="pt-6 pb-2 bg-gray-800 w-full flex flex-col justify-center items-center mt-10">
      <img src="small_title_reboot.png" alt="solana idols" />
      <div className="mb-3">
        <img
          src={`${process.env.PUBLIC_URL}/built_on_solana_white.png`}
          alt="built on solana"
          width={150}
        />
      </div>
      <span className="text-sm text-white">
        © 2022 Solana Idols | All Rights Reserved
      </span>
    </div>
  );
};

export default Footer;
