import React from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
// import Image from 'next/image'

const images = [3, 4, 2, 1000, 1635, 317, 652, 5, 1473, 10, 1649, 579, 970];

export default function ImageSlider() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 800, //399px以下のサイズに適用
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className="z-10">
        {images.map((image) => (
          <div key={image}>
            <img
              src={`${process.env.PUBLIC_URL}/slider/${image}.png`}
              alt={`${image}`}
              width={454}
              height={454}
            />
          </div>
        ))}
      </Slider>
    </>
  );
}
