// import React from "react";
// import Home from "../Home";
import Header from "./header";
import ImageSwap from "./image-swap";
// import * as anchor from "@project-serum/anchor";
// import { getCandyMachineId } from "../candyMachineUtils";
// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export default function Hero() {
  // const candyMachineId = getCandyMachineId();
  //   const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
  // const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
  // const connection = new anchor.web3.Connection(
  //   rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
  // );

  // const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
  // const txTimeoutInMilliseconds = 30000;

  return (
    <>
      <div className="w-full min-h-screen md:mb-0">
        <Header />
        <div
          className="flex flex-col justify-center items-center "
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <div className="max-w-6xl mx-auto flex flex-col-reverse md:flex-row ">
            <div className="p-10 w-full flex flex-col justify-center items-center z-10">
              <span className="text-gray-800 font-bold text-4xl md:text-6xl cursor-default style_title ">
                2,222 cute idols in the Solana universe.
              </span>
              <span className="text-gray-400 font-bold text-2xl cursor-default mt-8 style_title">
                Mint is closed!🔒
              </span>
              <span className="text-gray-600 font-bold text-2xl mt-3 style_title">
                Apply for MagicEden listing request within 24 hours.
                <br />
                <a
                  href="https://twitter.com/SolanaIdols"
                  className="text-pink-300"
                >
                  Check us out on Twitter!
                </a>
              </span>{" "}
              <div className="mt-10 flex flex-col justify-start items-start">
                <img
                  src={`${process.env.PUBLIC_URL}/built_on_solana.png`}
                  width={200}
                  height={30}
                  alt="solana"
                  className="zoom"
                />
              </div>
            </div>
            <div className="p-6 w-full flex flex-col justify-center items-center z-10">
              {/* キャッチー画像 */}
              <ImageSwap />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
