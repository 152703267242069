const roadmaps = [
  // {
  //   title: '25% Minted',
  //   content:
  //     'Exclusive airdops and giveaways for our Solana Idols members. These will be announced on our Twitter.',
  // },
  {
    title: "50% Minted",
    content:
      "We will host a HUGE Twitter giveaway. 20 SOL will be distributed to 20 people, each one will get 1 SOL randomly.",
  },

  {
    title: "100% Minted",
    content:
      "Exclusive airdops and giveaways for our Solana Idols members. These will be announced on our Twitter.",
  },
  {
    title: "After Successful Mint",
    content:
      "we will list to major marketplace exchanges(Probably MagicEden). So that community can head to different markets and trade Solana Idols asap.",
  },
  {
    title: "",
    content: "Hosting regular Giveaways/Collaborations with multiple projects",
  },
];

export default function Roadmap() {
  return (
    <div className="flex flex-col justify-start items-center md:items-start">
      <span className="text-gray-800 font-bold text-4xl md:text-6xl cursor-default style_title">
        Project
      </span>

      <span className="pl-6 style_pink font-bold text-4xl md:text-6xl cursor-default style_title mt-3 mb-5">
        Roadmap
      </span>
      <p className="pl-6 text-gray-500 style_title font-bold text-xl mb-10">
        Follow us on Twitter to get updates on our roadmap.
      </p>
      <div className="px-3  flex flex-col md:grid grid-cols-12 text-gray-50">
        {roadmaps.map((roadmap) => (
          <div className="flex md:contents" key={roadmap.title}>
            <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-800 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-800 shadow text-center text-pink-400">
                ❤
              </div>
            </div>
            <div className="bg-gray-800 col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full">
              <h3 className="font-semibold text-xl mb-1  style_title">
                {roadmap.title}
              </h3>
              <p className="text-justify w-full">{roadmap.content}</p>
            </div>
          </div>
        ))}

        {/* 
        <div className='flex md:contents'>
          <div className='col-start-2 col-end-4 mr-10 md:mx-auto relative'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-gray-300 pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow text-center'>
              <i className='fas fa-exclamation-circle text-gray-400'></i>
            </div>
          </div>
          <div className='bg-gray-300 col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full'>
            <h3 className='font-semibold text-lg mb-1 text-gray-400'>Delivered</h3>
            <p className='leading-tight text-justify'></p>
          </div>
        </div> */}
      </div>
      <div className="text-center">
        <small>
          Percentages are calculated from the total number of mints out of
          2,222, excluding the founders' share.
        </small>
      </div>
    </div>
  );
}
