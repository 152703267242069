import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
export default function Faq() {
  const faqs = [
    {
      ques: "What is Solana Idols series?",
      ans:
        "Solana Idols series is a generative art NFT series composed of 2,222 cute girl,using Solana culture as a motif.",
    },
    {
      ques:
        "What is the difference between the first 'Solana Idols' and 'Solana Idols Reboot'?",
      ans:
        "Solana Idols Reboot contains the first Solana Idols traits, with modified one and added variations. Solana Idols Reboot is more like version 1.5 than a sequel.",
    },
    {
      ques: "What is the mint price of a Solana Idols Reboot?",
      ans: "0.4 SOL( + some transaction fee). ",
    },

    {
      ques: "What Solana wallet do you recommend?",
      ans: "We recommend Phantom Wallet",
    },
    {
      ques: "Do you have a rarity system for Idols ?",
      ans:
        'Yes. Higher rarity items attribution will be given a "Special" value. Check your NFT trait metadata.',
    },
    // {
    //   ques: 'Do i own the idol?',
    //   ans: 'Yes, full intellectual properties are given to the buyer.',
    // },
  ];

  return (
    <div className="flex flex-col justify-start items-center md:items-start">
      {/* <img src='faq.png' alt='faq' className='mb-10' /> */}

      <span className="text-gray-800 font-bold text-4xl md:text-6xl cursor-default style_title">
        Frequently
      </span>
      <span className="pl-6 style_blue font-bold text-4xl md:text-6xl cursor-default style_title mt-3 mb-10">
        asked questions
      </span>

      <div className="mx-auto w-full flex flex-col justify-start items-start">
        <Accordion
          allowZeroExpanded
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {faqs.map((faq) => (
            <AccordionItem style={{ width: "100%" }} key={faq.ques}>
              <AccordionItemHeading>
                <AccordionItemButton>{faq.ques}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{faq.ans}</AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
