import "./App.css";
import { useMemo } from "react";
// import * as anchor from "@project-serum/anchor";
// import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

// import { ThemeProvider, createTheme } from "@material-ui/core";
import Main from "./Main";
import Gumdrop from "./components/Gumdrop";
// import { getCandyMachineId } from "./candyMachineUtils";

// const theme = createTheme({
//   palette: {
//     // type: "light",
//   },
// });

// const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
// const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
// const connection = new anchor.web3.Connection(
//   rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
// );

// const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
// const txTimeoutInMilliseconds = 30000;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletDialogProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/gumdrop" element={<Gumdrop />} />
            </Routes>
          </BrowserRouter>
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
