import React, { useEffect, useState } from "react";

const images = [
  8,
  7,
  1473,
  15,
  472,
  461,
  1508,
  108,
  1000,
  23,

  2,
  579,
  181,
  528,
  518,
];
export default function ImageSwap() {
  const [image, setImage] = useState(0);

  let i = 0;
  useEffect(() => {
    const interval = setInterval(() => {
      i++;
      setImage(i + 1);
      if (i > images.length - 2) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        i = 0;
        setImage(0);
      }
    }, 1 * 1000);
    return function cleanUp() {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <img
        src={`${process.env.PUBLIC_URL}/swap/${images[image]}.png`}
        alt={`${images[image]}`}
        width={500}
        height={500}
      />
    </div>
  );
}
