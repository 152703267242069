import React from "react";

const Header: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center md:items-start space-x-0 md:space-x-5 space-y-5 md:space-x-0">
      <div className="text-black space-x-5 text-lg font-bold flex items-center">
        <a href="https://www.solanaidols.com/">
          <img
            src={`${process.env.PUBLIC_URL}/title_reboot.png`}
            className="pt-2"
            alt="Solana Idols"
            width={330}
            height={211}
          />
        </a>
      </div>
      <div className="flex space-x-5 items-start">
        <div className="">{/* <WalletDisconnectButton /> */}</div>
      </div>
    </div>
  );
};

export default Header;
