import React from "react";
import Faq from "./components/faq";
import Footer from "./components/footer";
// import Header from './components/header';
import Hero from "./components/Hero2";
import ImageSlider from "./components/image-slider";
import Roadmap from "./components/roadmap";
import Team from "./components/team";

export default function Main() {
  return (
    <div>
      <Hero />

      {/* ここからslick+星+斜め線含む */}
      <div className="relative mt-40 md:mt-2">
        <img
          src={`${process.env.PUBLIC_URL}/star.png`}
          width={340}
          height={137}
          className="absolute w-2/6 md:w-auto -top-10 md:-top-36 right-0 md:right-16 z-20"
          alt="star"
        />
        <div className="w-full mx-auto">
          <ImageSlider />
        </div>
        <div className="w-full diagonal-box absolute bottom-0 left-0 top-0"></div>
      </div>
      {/* </div> */}
      {/* ここからメニュー */}

      <div className="mx-auto max-w-2xl mt-40">
        <Roadmap />
      </div>
      <div className="mx-auto max-w-2xl mt-40">
        <Team />
      </div>
      <div className="mx-auto max-w-2xl mt-40">
        <Faq />
      </div>

      <div className="mx-auto max-w-2xl mt-40 flex flex-col justify-center items-center">
        <span className="font-semibold text-3xl style_title">
          JOIN OUR COMMUNITY!
        </span>
        <div className="mt-3 flex flex-row w-2/5 justify-around">
          <a
            href="https://twitter.com/SolanaIdols"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icon_twitter.png`}
              alt="Twitter"
              className="zoom pb-6"
              width={53}
              height={53}
            />
          </a>
          <a
            href="https://discord.gg/dcmWjMbjmT"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icon_discord.png`}
              alt="Discord"
              className="zoom pb-6"
              width={53}
              height={53}
            />
          </a>
        </div>

        <div className="">
          <a
            className="twitter-timeline"
            data-height="500"
            data-theme="dark"
            href="https://twitter.com/SolanaIdols?ref_src=twsrc%5Etfw"
          >
            Tweets by SolanaIdols
          </a>{" "}
          <script async src="https://platform.twitter.com/widgets.js"></script>
        </div>
      </div>

      <Footer />
    </div>
  );
}
