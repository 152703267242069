import React from 'react'

export default function Team() {
  return (
    <div className='flex flex-col justify-start items-center md:items-start'>
      {/* <img src='team.png' alt='team' className='mb-10' /> */}

      <span className='text-gray-800 font-bold text-4xl md:text-6xl cursor-default style_title'>
        Our NFTs
      </span>
      <span className='pl-6 style_orange font-bold text-4xl md:text-6xl cursor-default style_title mt-3 mb-10'>
        Creater
      </span>

      <div className='mx-auto flex flex-col justify-start items-center '>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/avatar.png`}
            alt=''
            className='zoom'
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        </div>
        <div className='flex flex-row  justify-start items-center pt-4 font-bold text-4xl'>
          <span></span>Ronbun
          <a href='https://twitter.com/CryptoYuen' target='_blank' rel='noreferrer'>
            <img src={`${process.env.PUBLIC_URL}/twitter.png`} alt='' className='pl-3 zoom' />
          </a>
        </div>
        <span className='pt-4'>
          Artist, Web3 developer, Cute girl expert.
          <br />
          Twitter: @CryptoYuen
        </span>
      </div>
    </div>
  )
}
