import React, { useState } from "react";

import urls from "../urls.json";
import Header from "./header";

export default function Gumdrop() {
  const [address, setAddress] = useState("");
  // onChange で取得
  const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAddress(value);
  };
  const habdleCheckButton = () => {
    let flag = false;
    for (let url of urls) {
      if (address === url.handle) {
        flag = true;
        // リダイレクト
        // window.location = url.url as any;
        window.open(url.url as any, "_blank");
      }
    }
    if (flag === false) {
      alert("Wrong address");
    }
  };

  return (
    <div className="">
      <Header />
      <div className="flex items-center justify-center flex-col w-full">
        <main className="flex flex-col max-w-6xl mt-10">
          <span className="text-gray-800 font-bold text-4xl md:text-6xl cursor-default style_title">
            Get
          </span>
          <span className="pl-6 style_pink font-bold text-4xl md:text-6xl cursor-default style_title mt-3 mb-5">
            Mint token
          </span>
          <p className="pl-6 text-gray-500 style_title font-bold text-xl">
            Whitelist members can get Reboot NFT for (almost) free!
          </p>

          <p className="mx-20 mt-10">
            Anyone who was a Solana Idols holder as of January 27, 2022, 0:00:00
            UTC will automatically be whitelisted. <br />
            Members of the whitelist will get Solana Idols Reboot NFT for almost
            free(0.0001 SOL. Plus mint fee ≈ 0.012 SOL are required).
            <br />
            Fill in the form below with your <b>wallet address</b> and click on
            "GET MINT TOKEN". You will be redirected to Gumdrop to get your mint
            token.
            <br />
            See how to get mint token in the following section.
            <br />
            <br />
            2022年1月27日0時0分(UTC)の時点でSolana
            Idolsホルダーだった人物は自動的にホワイトリストに入れられます。
            <br />
            ホワイトリストのメンバーは、Solana Idols Reboot
            NFTをほとんど無料(0.0001 SOL。これに加えてMint手数料 ≒ 0.012
            SOLが必要)で入手することができます。
            <br />
            以下のフォームにウォレットアドレスを記入し、「GET MINT
            TOKEN」をクリックしてください。Whitelist参加者にミントトークンを配布するGumdropというサイトにリダイレクトします。
            <br />
            より詳細な解説は下のセクションよりご覧になられます。
          </p>
          <p className="mt-8 text-center text-gray-500  font-bold text-xl ">
            We are currently stopping due to an error. Sorry for the
            inconvenience.
            {/* For more details on public mint date, check Solana Idols Twitter or
            Discord. */}
          </p>
          <p className="mt-8 text-center text-gray-500  font-bold text-xl ">
            エラーが発生しているため現在停止中です。ご不便お詫び申し上げます。
            {/* ミント公開の詳細な日にちは、Solana
            IdolsのTwitterまたはDiscordでご確認ください。 */}
          </p>
        </main>
        <div className="mt-16">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="wallet"
          >
            Your wallet address
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="wallet"
            onChange={onChangeAddress}
            value={address}
            disabled
          />
          <button
            onClick={() => habdleCheckButton()}
            className="button mt-10 style_title pulse w-full"
            disabled
          >
            🎁 GET MINT TOKEN 🎁
          </button>
        </div>
        <div className="flex flex-col max-w-6xl mt-10 justify-center items-center">
          <div className="mt-20 flex flex-col justify-center items-center">
            <img
              src={`${process.env.PUBLIC_URL}/tutorial/1.png`}
              alt="1"
              width={700}
            />
            <p className="mt-10 text-justify">
              First, make sure that the drop-down in the upper right corner is
              set to "mainnet-beta", and then click on "CONNECT".
              <br />
              Then approve the use of your wallet address (e.g. Phantom).
            </p>
            <p className="mt-10 text-justify">
              まず右上のドロップダウンリストが"mainnet-beta"になってるか確認して、"CONNECT"をクリックしてください。
              <br />
              その後お使いのウォレットアドレス(例えばPhantom)の使用を承認してください。
            </p>
          </div>
          <div className="mt-20 flex flex-col justify-center items-center">
            <img
              src={`${process.env.PUBLIC_URL}/tutorial/2.png`}
              alt="2"
              width={700}
            />
            <p className="mt-10 text-justify">
              All the settings are automatically filled in the form, so all you
              have to do is click "CLAIM GUMDROP".
            </p>
            <p className="mt-10 text-justify">
              フォームが並んでいますが自動的に記入されています。あとは"CLAIM
              GUMDROP"をクリックするだけです。
            </p>
          </div>
          <div className="mt-20 flex flex-col justify-center items-center mb-32">
            <img src={`${process.env.PUBLIC_URL}/tutorial/3.png`} alt="3" />
            <p className="mt-10 text-justify ">
              Wallet opens and asks for approval. You will see an increase in
              the token <b>"GdK4L..."</b>.This <b>"GdK4L..."</b> is the mint
              token.
              <br />
              (Also make sure that your SOL is reduced by the appropriate amount
              for TX fees. Keep an eye out for scams!)
              <br />
              Click Approve and you will have a mint token to redeem for Solana
              Idols Reboot NFT.
              <br /> Congratulations!
            </p>
            <p className="mt-10 text-justify">
              ウォレットが開き、承認の確認をします。このとき<b>"GdK4L..."</b>
              というトークンが増えることを確認してください。この
              <b>"GdK4L..."</b>というものがミントトークンです。
              <br />
              (手数料として適切な量だけSOLが減っているかも確認してください。scamには常に警戒を！)
              <br />
              問題がなければ「承認」をクリックしてください。これはSolana Idols
              Rebootと引き換えになるトークンとなります。
              <br />
              お疲れさまでした！
            </p>

            <p className="mt-8 text-center text-gray-500  font-bold text-xl ">
              For more details on public mint date, check Solana Idols Twitter
              or Discord.
            </p>
            <p className="mt-8 text-center text-gray-500  font-bold text-xl ">
              ミント公開の詳細な日にちは、Solana
              IdolsのTwitterまたはDiscordでご確認ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
